<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo d-flex align-items-center">
          <!-- <img
            height="40"
            src="@/assets/images/logo/logo.svg"
            alt=""
               > -->

          <h2 class="brand-text mt-1 mb-0 ml-1">tourinoTravel</h2>
        </b-link>

        <b-card-text class="mb-2">
          برای استفاده از پنل مدیریت لطفا ابتدا وارد شوید
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- mobile -->
            <b-form-group label-for="mobile" label="موبایل">
              <validation-provider
                #default="{ errors }"
                name="mobile"
                rules="required"
              >
                <b-form-input
                dir="ltr"
                  id="mobile"
                  v-model="usermobile"
                  name="login-mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="09124445566"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">رمز</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="login()"
            >
              <div v-if="loading" class="px-1">
                <b-spinner small style="width: 1.3rem; height: 1.3rem" />
                <span class="sr-only">Loading...</span>
              </div>
              <span v-else> ورود </span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import StorageService from "@/services/storage";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, mobile } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      usermobile: "",
      password: "",
      status: "",
      loading: false,
      // validation rules
      required,
      mobile,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  mounted() {
    StorageService.setLanguage("fa");
    this.$i18n.locale = "fa";
  },
  methods: {
   async login() {

var mobile= parseInt(this.usermobile, 10);
mobile=`+98${mobile}`

      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          if (this.loading) return;
          this.loading = true;
          const requestData = {
            method: "POST",
            url: "auth/request",
            data: {
              mobile: mobile,
              password: this.password,
              login_type:"custom"
              // remember: this.status,
            },
          };

          await this.$http(requestData)
            .then((response) => {
               StorageService.setToken(response.data.data.api_token);
               StorageService.setUserData(response.data.data);
              if (this.$route.params.nextUrl) {
                window.location.href = this.$route.params.nextUrl;
                 location.reload();
                return;
                // return this.$router
                //   .push({ path: this.$route.params.nextUrl })
                //   .catch(() => {});
              } else {
                window.location.href = "/";
                 location.reload();
                return;
                // return this.$router.push({ path: "/" }).catch(() => {});
              }
            })
            .catch((error) => {
              let message = `Unable to login!`;
              console.log("error",error)
              if (!error) {
                return;
              }
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              )
                message = error.response.data.message;
              this.$bvToast.toast(message, {
                title: this.$t("Error"),
                variant: "danger",
                solid: true,
              });
              console.log(error);
            });

          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
